import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const [active, setActive] = useState('')

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => {
          scrollTo("about")
          setActive('about')
        }}>
          {
            active === "about" ? <Span> <Button>{t("About")}</Button>    </Span> : <Span>{t("About")}</Span>
          }
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => {
          scrollTo("mission")
          setActive('mission')
        }}>
          {
            active === "mission" ? <Span> <Button>{t("Mission")}</Button>    </Span> : <Span>{t("Mission")}</Span>
          }
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => {
          scrollTo("product")
          setActive('product')
        }}>
          {
            active === "product" ? <Span> <Button>{t("Product")}</Button>    </Span> : <Span>{t("Product")}</Span>
          }
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => {
            scrollTo("contact")
            setActive('contact')
          }}
        >

          {active === "contact" ? <Span> <Button>{t("Contact")}</Button>    </Span> : <Span>{t("Contact")}</Span>}

        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify='space-between'>
          <LogoContainer to='/' aria-label='homepage'>
            <SvgIcon extension="svg" src='logo.svg' width='101px' height='64px' />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
